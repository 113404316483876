// Load Styles
import '../scss/main.scss';

import $ from 'jquery';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
// console.log(`Hello ${process.env.HELLO}`);

import 'select2';
import 'slick-carousel';
import 'jquery-match-height';

$(function () {
  //## jQuery
  // const winH = $(window).height();
  // console.log(`Window height: ${winH}`);
  // const winW = $(window).width();
  // console.log(`Window width: ${winW}`);

  //## matchHeight
  if ($.fn.matchHeight) {
    $('.same-height').matchHeight({
      byRow: true,
      property: 'height',
      target: null,
      remove: false,
    });
  }
  //## Slick
  if ($.fn.slick) {
    $("#banners.slider").slick({
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 500,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      // variableWidth: true,
      fade: true,
      cssEase: 'linear',
      responsive: [
        // {
        //   breakpoint: 1024,
        //   settings: {
        //     dots: false,
        //   },
        // },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
          },
        },
      ],
    });
    $("#promo-list").slick({
      dots: false,
      arrows: true,
      // infinite: true,
      // autoplay: false,
      // autoplaySpeed: 1000,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: false,
      // centerPadding: '60px',
      // variableWidth: true,
      // fade: true,
      // cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            dots: true,
            arrows: false,
          },
        },
      ],
    });
    $("#img-product").slick({
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 1000,
      speed: 500,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      // variableWidth: true,
      fade: true,
      cssEase: 'linear',
      responsive: [
        // {
        //   breakpoint: 1024,
        //   settings: {
        //     dots: false,
        //   },
        // },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
          },
        },
      ],
    });
    $("#brand-carousel").slick({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      // variableWidth: true,
      fade: false,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    });
  }
  $('.submenu a.multiple').on("click", function (e) {
    $(this).next('.dropdown-menu').toggle();
    e.stopPropagation();
    e.preventDefault();
  });

  function formatBrand(brand) {
    if (!brand.id) {
      return brand.text;
    }
    var baseUrl = "./images";
    var $brand = $(
      '<span><img src="' + baseUrl + '/logo-' + brand.element.value.toLowerCase() + '.png" class="img-brand-option me-2" height="20" /> ' + brand.text + '</span>'
    );
    return $brand;
  };

  //## select2
  var select2Init = function () {
    if ($.fn.select2) {
      // $.fn.select2.defaults.set('theme', 'bootstrap');
      // $('.select2').select2();

      $('.select2').select2({
        theme: "bootstrap-5",
        width: '100%',
        placeholder: function () {
          $(this).data('placeholder');
        },
        allowClear: true
      });
      $('.select2-brand').select2({
        theme: "bootstrap-5",
        width: '100%',
        placeholder: function () {
          $(this).data('placeholder');
        },
        allowClear: true,
        templateResult: formatBrand
      });
    }
  };

  select2Init();
});
